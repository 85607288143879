<script setup lang="ts">
  import MenuService from '~/services/MenuService'

  const navigationFooter = MenuService.footerMenuItens
</script>

<template>
  <footer
    class="mt-10 border-t bg-gray-50 lg:mt-20"
    aria-labelledby="footer-heading">
    <h2
      id="footer-heading"
      class="sr-only">
      Footer
    </h2>
    <div
      class="mx-auto flex max-w-7xl flex-wrap px-4 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-10">
      <div class="flex w-full flex-col space-y-4 md:mt-16 lg:w-1/5">
        <a href="/"
          ><img
            class="h-9"
            src="/img/emfesta-logo.png"
            alt="Emfesta logotipo"
        /></a>
        <div class="flex space-x-6">
          <a
            v-for="item in navigationFooter.social"
            :key="item.name"
            :href="item.href"
            target="_blank"
            class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <component
              :is="item.icon"
              class="h-6 w-6"
              aria-hidden="true" />
          </a>
        </div>
      </div>
      <div class="mt-16 w-1/2 sm:w-1/4 lg:w-1/5">
        <h3 class="text-sm font-semibold leading-6 text-gray-900">Empresa</h3>
        <ul
          role="list"
          class="mt-6 space-y-4">
          <li
            v-for="item in navigationFooter.company"
            :key="item.name">
            <a
              :href="item.href"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="mt-16 w-1/2 sm:w-1/4 lg:w-1/5">
        <h3 class="text-sm font-semibold leading-6 text-gray-900">Cidades</h3>
        <ul
          role="list"
          class="mt-6 space-y-4">
          <li
            v-for="item in navigationFooter.cities"
            :key="item.name">
            <a
              :href="item.href"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="mt-16 w-1/2 sm:w-1/4 lg:w-1/5">
        <h3 class="text-sm font-semibold leading-6 text-gray-900">Bairros</h3>
        <ul
          role="list"
          class="mt-6 space-y-4">
          <li
            v-for="item in navigationFooter.neighborhoods"
            :key="item.name">
            <a
              :href="item.href"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="mt-16 w-1/2 sm:w-1/4 lg:w-1/5">
        <h3 class="text-sm font-semibold leading-6 text-gray-900">Tipos</h3>
        <ul
          role="list"
          class="mt-6 space-y-4">
          <li
            v-for="item in navigationFooter.types"
            :key="item.name">
            <a
              :href="item.href"
              class="text-sm leading-6 text-gray-600 hover:text-gray-900"
              >{{ item.name }}</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="mt-8 border-t border-gray-900/10 p-4 sm:mt-20 lg:mt-24">
      <p class="text-center text-xs leading-3 text-gray-500 md:text-sm">
        &copy; Emfesta Soluções Tecnológicas I.S. - 45.560.261/0001-02
      </p>
      <p class="text-center text-xs leading-3 text-gray-500 md:text-sm">
        Rio de Janeiro, RJ
      </p>
    </div>
  </footer>
</template>
